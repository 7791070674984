@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}
.txt1 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 13px;
    line-height: 1.5;
    color: #999999;
}
.txt2 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}
.limiter {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
}
.container-login100 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.wrap-login100 {
    // width: 900px;
    // background: #443e97;
    // background: #002FA7;
    background: #001138;
    border-radius: 10px;
    margin: 10px auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: 70px 50px;
    z-index: 999;
    box-shadow: 0px 8px 8px rgba(10, 16, 20, 0.24), 0px 0px 8px rgba(10, 16, 20, 0.12);
}
@media (max-width:768px) {
    .wrap-login100 {
        max-width: 900px;
        width: 100%;
    }

}

.login100-pic {
    width: 316px;
    position: relative;
    img {
        max-width: 100%;
        margin-top: 60px;
    }
}

.login100-form {
    width: 350px;
}
.login100-form-title {
    font-family: 'Noto Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 40px;
}
.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}

.input100 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
}


.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(87,184,70, 0.8);
}

.input100{
    &:focus {
        + {
            .focus-input100 {
                -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
                animation: anim-shadow 0.5s ease-in-out forwards;
            }
        }
    }
}

.symbol-input100 {
    font-size: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    pointer-events: none;
    color: #C3CFD5;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100{
    &:focus {
        + {
            .focus-input100 {
                + {
                    .symbol-input100 {
                        color: #57b846;
                        padding-left: 28px;
                    }
                }
            }
        }
    }
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 50px;
}

.login100-form-btn {
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    text-transform: none;
    width: 100%;
    height: 44px;
    border-radius: 25px;
    background: #ea4335;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

@media (max-width: 992px) {
    .wrap-login100 {
        padding: 177px 90px 33px 85px;
    }

    .login100-pic {
        width: 35%;
    }

    .login100-form {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
    }

    .login100-pic {
        display: none;
    }

    .login100-form {
        width: 100%;
        z-index: 2;
    }
}

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 100px 15px 33px 15px;
    }
}


.validate-input {
    position: relative;
}

.alert-validate{
    &::before {
        content: attr(data-validate);
        position: absolute;
        max-width: 70%;
        background-color: white;
        border: 1px solid #c80000;
        border-radius: 13px;
        padding: 4px 25px 4px 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 8px;
        pointer-events: none;
        font-family: 'Noto Sans', sans-serif;
        color: #c80000;
        font-size: 13px;
        line-height: 1.4;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.4s;
        -o-transition: opacity 0.4s;
        -moz-transition: opacity 0.4s;
        transition: opacity 0.4s;
    }
}

.alert-validate{
    &::after {
        content: "\f06a";
        font-family: FontAwesome;
        display: block;
        position: absolute;
        color: #c80000;
        font-size: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 13px;
    }
}

.alert-validate{
    &:hover{
        &:before {
            visibility: visible;
            opacity: 1;
        }
    }
}

@media (max-width: 992px) {
    .alert-validate{
        &::before {
            visibility: visible;
            opacity: 1;
        }
    }
}

.login-logo {
    img {
        width: 100px;
    }
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 50px;
    transition: all 200ms;
    opacity: 0.5;
}

.form-control{
    &:valid {
        + {
            .form-control-placeholder {
                font-size: 75%;
                transform: translate3d(0, -100%, 0);
                opacity: 1;
            }
        }
    }
    &:focus {
        + {
            .form-control-placeholder {
                font-size: 75%;
                transform: translate3d(0, -100%, 0);
                opacity: 1;
            }
        }
    }
}

.login100-form {
    .form-control{
        &:focus {
            color: #495057;
            background-color: #FFF;
            border-color: #80bdff00;
            border: none;
            outline: none;
            box-shadow: none;
            border-bottom: 1px solid #4285f4 !important;
        }
    }
}

.login100-form {
    .form-control {
        display: block;
        width: 100%;
        font-size: 14px !important;
        padding: 0.5rem 1.75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #495057;
        background-color: #FFF;
        background-image: none;
        background-clip: padding-box;
        border: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 0 !important;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    }
}

.login100-form {
    .form-group {
        .form-control-placeholder {
            font-size: 14px !important;
            color: #83898C;
        }
    }
}

.login100-social-item {
    font-size: 25px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px;
}

.bg1 {
    background-color: #3B5998;
}

.bg2 {
    background-color: #1DA1F2;
}

.bg3 {
    background-color: #EA4335;
}

.login-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 9999;
    text-align: left;
}

.left-side-img-login {
    position: absolute;
    left: -30px;
    bottom: 40px;
}

.left-side-img-login-a {
    position: absolute;
    left: -100px;
    bottom: 94px;
}

.left-side-img-login-b {
    position: absolute;
    right: -30px;
    top: 94px;
}

.left-side-img-login-c {
    position: absolute;
    right: -50px;
    top: 40px;
    z-index: 1;
}

.otp-button {
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    line-height: 1.5;
    color: #ea4335;
    text-transform: none;
    width: 100%;
    height: 44px;
    border-radius: 25px;
    background: rgb(255, 255, 255);
    border: 1px solid #ea4335;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn{
    &:hover {
        color: #ea4335;
        background-color: #fff;
        border: 1px solid #ea4335;
    }
}

.otp-button{
    &:hover {
        color: #fff;
        background-color: #ea4335;
        border: 1px solid #ea4335;
    }
}

.otp-logo {
    text-align: center;
    img {
        width: 200px;
    }
}

.otp-verification-text {
    padding: 15px 0;
    h4 {
        margin: 15px 0;
    }
    h6 {
        line-height: 20px;
        margin-bottom: 10px;
    }
}

.OTP_modal {
    .modal-header{
        border: 0;
        padding: 20px;
        width: 100%;
    }
	.modal-footer {
		justify-content: center;
		padding: 0 30px 30px 30px;
		border: 0;
	}
}
.OTP_receive_modal {
    overflow-x: hidden;
    overflow-y: auto;
    .modal-header{
        border: 0;
        padding: 20px;
        width: 100%;
    }
	.modal-footer {
		justify-content: center;
		padding: 0 30px 30px 30px;
		border: 0;
	}
}
.input-otp {
    padding: 15px 0;
    input.otp_input_feild {
        border-bottom: 1px solid #000000;
        width: 100px;
        text-align: center;
        font-size: 24px;
    }
    .form-control-placeholder {
        position: absolute;
        top: 0;
        left: 25%;
        padding: 7px 0 0 0px;
        transition: all 200ms;
        opacity: 0.5;
    }
	.form-control {
		border: none;
		border-bottom: 1px solid #ced4da;
		border-radius: 0;
		box-shadow: none;
		text-align: center;
		&:focus {
			+ {
				.form-control-placeholder {
					font-size: 75%;
					transform: translate3d(0, -100%, 0);
					opacity: 1;
					color: #32a351;
					left: 27%;
				}
			}
			border-bottom: 1px solid #32a351 !important;
		}
	}
	.form-group {
		margin: 0;
    }
    input.otp_input_feild{
        &:focus {
            border-bottom: 1px solid #000000 !important;
        }
    }
    
}
.form-control {
	&:valid {
		+ {
			.form-control-placeholder {
				font-size: 75%;
				transform: translate3d(0, -100%, 0);
				opacity: 1;
				color: #32a351;
				left: 27%;
			}
		}
	}
}

.otp-verification-text{
     span {
        color: #eab000;
        font-weight: bold;
    }
}
label.not-clicked {
    color: #ea4335;
    span{
         a{
            color: #ea4335;
        }
    }
}
.remember_me {
    .form-check {
        .form-check-input {
            left: 15px;
        }
    }
}
.login-cust-form {
    position: relative;
    width: 100%;
    display: flex;
}
.flex-c-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
}
.login100-social-item.bg1{
    &:hover {
        color: #3b5998;
        background: #ffffff;
    }
}
.login100-social-item.bg3{
    :hover {
        color: #EA4335;
        background: #ffffff;
    }
}
.OTP_receive_modal.otp-verify-custom {
    margin: 0;
}
.login-logo-same {
    img {
        width: 100px;
    }
}
.CAlign-div{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.bg-basic {
    background-color: #56be8e;
}
.bg-premium {
    background-color: #3769cc;
}
.select-package-fixed-heght {
    min-height: 240px;
    height: auto;
}
.bg-basic-button{
    background-color: #56be8e;
    color: #fff;
}
.bg-premium-button{
    background-color: #3769cc;
    color: #fff;
}
.theme-text-one{
    color: #ea4335 !important;
}
img.form-logo {
    max-width: 60px;
    height: auto;
    margin-bottom: 15px;
}

.has-form-layout {
    p {
        color: #fff;
        a{
            color: #fff;
        }
    }
    .ant-btn-danger {
        color: #fff;
        background-color: #002FA7;
        border-color: #002FA7;
        width: 100%;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-danger:hover {
        color: #fff;
        background-color: #002FA7;
        border-color: #002FA7;
    }
    .ant-btn-danger:focus {
        color: #fff;
        background-color: #002FA7;
        border-color: #002FA7;
    }
    .has-feedback {
        span.ant-input-affix-wrapper {
            border-radius: 2px;
            padding: 4px 11px;
        }
    }
    .checkbox.icheck {
        .ant-checkbox {
            + {
                span {
                    padding-right: 8px;
                    padding-left: 8px;
                    color: #fff;
                }
            }
        }
        a {
            color: #fff;
            padding-top: 4px;
        }
    }
    .signup-text {
        span.txt1 {
            color: #fff;
        }
        span{
            a{
                color: #fff;
            }
        }
    }
}
.cu-sidebar {
    background: #443e97;
    .ant-menu-dark{
        .ant-menu-sub {
            color: #fff;
            background: #58c5c4;
        }
        .ant-menu-item {
            > {
                a {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
        .ant-menu-item-selected {
            background-color: #58c5c4 !important;
        }
    }
}
@media (max-width:767px){
    .cu-form-content {
        .ant-card-body {
            padding: 0;
            margin: 0;
        }
    }
    .sm-mt-3{
        margin-top: 14px !important;
    }
    .ant-steps {
        overflow: auto;
        padding-bottom: 10px !important;
    }
    .ant-layout-content {
        overflow: hidden;
    }
    .admin-dashboard {
        overflow: hidden;
    }
    .sb-open .admin-dashboard .ant-input-search.ant-input-affix-wrapper {
        width: auto !important;
    }
    .ant-picker-range {
        width: 100% !important;
    }
    .ant-layout-header {
        padding: 0 25px !important;
    }
    .ant-picker {
        width: 100% !important;
    }
    .fc-toolbar h2 {
        font-size: 1.2em;
        margin: 0;
    }
}