@import "./form-layout.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap');

.cursor-pointer {
    cursor: pointer !important;
}

.checkbox.icheck{
     input.form-control {
        -webkit-appearance: checkbox;
        display: inline-block !important;
        width: auto;
        margin-right: 5px;
    }
}

.inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.wid-10 {
    width: 10%;
}
.main-header {
    border-bottom: none;
    background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
    box-shadow: 0 2px 5px 0 rgb(43, 80, 96), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.navbar {
    font-weight: 300;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgb(43, 80, 96), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.logo-lg img {
    width: 80px;
}
/* *****************************Student Dashboard**************************** */
.student-content-wrapper {
    background-color: #f0f4f8;
    height: 100%;
    padding-bottom: 77px;
}
.s-dashboard-height{
    height: calc(100vh - 137px);
}
.Student-footer {
    background: #fff;
    padding: 15px;
    color: #444;
    font-size: 13px;
    border-top: 1px solid #d2d6de;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100% ;
}
.student-content-wrapper, .Student-footer{
    -webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out, margin .3s ease-in-out;
    -webkit-transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out, margin .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    margin-left: 0;
    z-index: 820;
}
.student-dashboard {
    .main-header {
        .navbar-custom-menu {
            .ant-dropdown-link {
                i.anticon.anticon-down {
                    font-size: 16px;
                }
            }
        }
    }
}
.nav-logo {
    padding: 10px 0;
}
p {
    font-size: 14px;
}
.element-icon {
    padding: 0 10px;
    position: relative!important;
    float: left;
    width: 100%;
    transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    img {
        max-width: 200px;
        transition: transform .5s,filter .5s ease-in-out;
    }
    .rotating-icon {
        img {
            width: 100%;
            max-width: 70px;
        }
    }
    .rotating-icon {
        position: absolute;
        top: 38px;
        left: 76px;
        transition: .5s ease-in-out;
    }
}
.s-dashboard{ 
    ul {
        li.dash-icon {
            transition: .5s all;
        }
    }
}
.s-dashboard {
	ul {
		li.dash-icon {
			&:hover {
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				transform: scale(1.3);
				z-index: 10000;
				transition: .5s all;
			}
		}
	}
}

ul.row.inline-block {
    li.inline-block{
        &:hover {
            .rotating-icon {
                transform: rotate(360deg);
                transition: .5s ease-in-out;
            }
        }
    }
}
@media (min-width:992px)
{
    .s-dashboard {
        padding: 60px;
    }
    .s-dashboard.topscholars {
        padding: 60px 20px;
    }
}
@media (max-width:992px)
{
    .hide-mob {
        display: none;
    }
}
.profile-box{
    .ant-form-item {
        .ant-form-item-label {
            line-height: normal;
        }
    }
}
span.camera-img {
    position: absolute;
    bottom: 0;
    left: 120px;
    z-index: 99;
}
.reset-pass {
    .img-fluid {
        max-width: 300px;
        width: 100%;
    }
}
.selectedPackage {
    -webkit-box-shadow: 0 2px 5px 0 rgba(234, 67, 53, 0.5), 0 2px 10px 0 rgba(234, 67, 53, 0.5);
    box-shadow: 0 2px 5px 0 rgba(234, 67, 53, 0.5), 0 2px 10px 0 rgba(234, 67, 53, 0.5);
}
.min-h-600{
    min-height: 600px;
}
.book-cover {
    a {
        img {
            max-width: 160px;
            transition: transform .5s,filter .5s ease-in-out;
        }
    }
}
.s-learntestanalysis {
    .s-dashboard {
        ul {
            li{
                &:hover {
                    .book-cover {
                        a {
                            img{
                                -moz-transform: scale(1.1);
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                z-index: 10000;
                                transition: .5s all;
                            }
                        }
                    }
                }
            }
        }
    }
}
.s-chaptervideos {
    .open-Video-modal {
        width: 270px;
        height: 150px;
        position: absolute;
        background: #fff0;
        left: 15px;
        z-index: 9;
    }
}
table{
     td {
        font-weight: 400;
    }
}
.rank-tag {
    position: absolute;
    right: 0;
    top: 70px;
}
.notice-board {
    .nbg1 {
        transform: rotate(-3deg);
        background-color: #fbbc05;
    }
    .nbg2 {
        transform: rotate(-10deg);
        background-color: #ea4335;
    }
    .nbg3 {
        transform: rotate(-1deg);
        background-color: #23cc52;
    }
    .nbg4 {
        transform: rotate(4deg);
        background-color: #5a95fa;
    }
    .notice-board-1 {
        width: 250px;
        height: 250px;
        border-radius: 4px;
        position: relative;
    }
}
.exam-summary {
    .card.custom-card.result-list {
        float: left;
        display: block;
        width: 100%;
    }
    .card.card-cascade.narrower {
        margin-top: 1.25rem;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        margin-bottom: 40px;
    }
    .view {
        position: relative;
        overflow: hidden;
        cursor: default;
    }
}
.summarg_bg_1 {
    background: #4285f4;
}
.summarg_bg_2 {
    background: #ea4335;
}
.summarg_bg_3 {
    background: #fbbc05;
}
.summarg_bg_4 {
    background: #34a853;
}
 .card.card-cascade {
    .view.view-cascade {
        border-radius: .25rem;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    }
    .view.view-cascade.gradient-card-header {
        padding: 1.6rem 1rem;
        color: #fff;
        text-align: center;
    }
}
 .card.card-cascade.narrower {
    margin-top: 1.25rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    margin-bottom: 40px;

    .view.view-cascade {
        margin-left: 2%;
        margin-right: 2%;
        margin-top: -1.25rem;
    }
}
 .table {
    td {
        padding: 10px 5px;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        color: #000;
        font-weight: 400;
    }
    th {
        padding: 10px 5px;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        font-weight: 500;
        color: #000;
    }
    a {
        margin: 0;
        color: #1890ff;
    }
}
.ant-btn-success {
    background-color: #28a745 !important;
    color: #fff !important;
}
.ant-btn-color-theme{
    background-color:#f44336 !important;
    color: #fff !important;
}
.Wrong_answer {
    color: red !important;
}
.menu-side-bar {
    position: fixed;
    left: 0;
    top: 60px;
    height: 100%;
    overflow: auto;
    z-index: 999;
    border-right: 1px solid #ccc;
    background: #fff;
    padding-bottom: 77px;
}
.main-panelwsidebar {
    position: relative;
    margin-left: 230px;
}
div#app {
    min-height: 100%;
    position: relative;
}
.s-ebooks{
    ul {
        li {
            transition: .5s all;
            &:hover{
                .book-cover {
                    a {
                        img {
                            -moz-transform: scale(1.1);
                            -webkit-transform: scale(1.1);
                            transform: scale(1.1);
                            z-index: 10000;
                            transition: .5s all;
                        }
                    }
                }
            }
        }
    }
}
.page_404 {
    padding: 100px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    overflow: auto;
    img {
        width: 100%;
    }
    .four_zero_four_bg {
        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
        height: 450px;
        background-position: center;
        background-size: cover;
        width: 100%;
        h1 {
            font-size: 40px;
            font-weight: 600;
        }
        h3 {
            font-size: 80px;
        }
    }
    .link_404 {
        color: #fff !important;
        padding: 10px 20px;
        background: #39ac31;
        margin: 20px 0;
        display: inline-block;
    }
    .contant_box_404 {
        margin-top: -50px;
    }
    
}
.total-no-question {
    padding: 0px 0px 20px 0px;
    position: relative;
    border: 0;
    border-bottom: 1px solid #000000;
    border-style: dashed;
    .question-text {
        color: #fd4b7e;
        font-size: 26px;
        font-weight: 800;
    }
}
p.quiz-instruction-details {
    border: 0;
    border-bottom: 1px solid #000000;
    border-style: dashed;
    padding: 20px 0px;
    font-size: 20px;
    color: #000000;
    font-weight: 800;
}

span.font-size-14 {
    font-size: 22px;
    color: #000000;
}
.instruction-details{
    p {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
    }
}
.s-online-exam {
    background: radial-gradient(circle, #24246e, #06051f);
    min-height: 100vh;
    .timer {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        border: 5px solid #4285f4;
        border-radius: 50%;
        span {
            font-size: 22px;
            font-weight: 700;
            color: #ffffff;
        }
        h5 {
            color: #34a853;
        }
    }
    .total-no-question {
        padding: 0px 0px 20px 0px;
        position: relative;
        border: 0;
        border:0;
        border-style: dashed;
        .question-text {
            color: #fd4b7e;
            font-size: 26px;
            font-weight: 800;
            border-radius: 4px;
            padding: 10px;
        }
    }
    .answer-options {
        padding: 20px 0;
        .ant-radio-group {
            width: 100%;
        }
    }
    p.question {
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        border: 0;
        border-top: 1px solid #ffffff;
        border-style: dashed;
        padding-top: 15px;
        margin-top: 15px;
    }
    .ant-radio-button-wrapper{
        background: transparent !important;
        color: #ffffff;
        &::before {
            position: absolute;
            top: 0;
            left: -1px;
            display: block;
            width: 1px;
            height: 100%;
            background-color: transparent !important;
            content: '';
        }
        .ant-radio-button-wrapper-checked{
            color: #fb4894 !important;
            border-color: #fb4894 !important;
            &:not(.ant-radio-button-wrapper-disabled) {
                z-index: 1;
                color: #fb4894 !important;
                background: transparent;
                border-color: #fb4894 !important;
                -webkit-box-shadow: -1px 0 0 0 #fb4894;
                box-shadow: -1px 0 0 0 #fb4894;
            }
        }
    }
}
.xs-d {
    display: block;
}
.sm-d {
    display: none;
}
@media (max-width:767px)
{
    .s-dashboard {
        padding: 15px 0;
    }
    .notice-board ul {
        padding-left: 0;
    }
    .topscholars {
        .ant-card-body {
            .ant-col.ant-col-8 {
                width: 100%;
            }
        }
    }
    .menu-side-bar {
        display: none;
    }
    .main-panelwsidebar {
        margin-left: 0;
    }
    .menu-bar {
        font-size: 16px !important;
    }
    .xs-d {
        display: none;
    }
    .sm-d {
        display: block;
    }
}
/*  ANT layout css */
    #components-layout-demo-custom-trigger{
        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color 0.3s;
            &:hover {
                color: #1890ff;
              }
        }
        .logo {
            height: 32px;
            background: rgba(255, 255, 255, 0.2);
            margin: 16px;
          }
  }
.admin-layout {
    .logo {
        height: auto;
        background: transparent;
        margin: 7px;
        text-align: center;
    }
}
.form-group {
    position: relative;
    margin-bottom: 1.5rem ;
}
.warning-msg {
    position: absolute;
    left: 0;
    bottom: -17px;
    font-size: 12px;
}
.inline-block {
    display: inline-block !important;
}
.ant-btn-link-danger{
     i.anticon {
        color: #ff4d4f;
    }
}
.ant-btn-link-danger.ant-btn-circle {
    border: 0;
    background: transparent !important ;
}
.ant-form-item-label, .ant-btn {
    line-height: normal !important;
}
.input-otp input.ant-input {
    text-align: center;
}
.sidebar-acolor{
    color: rgba(255, 255, 255, 0.65) !important;
}
.anchor-color
{
    color: rgba(0, 0, 0, 0.65) !important;
}
.fw-500
{
    font-weight: 500 !important;
}
.from-field-input {
    .ant-form-item-label {
        display: block;
        text-align: left;
        padding: 0 !important;
    }
}
.ant-btn-link-danger {
    span.anticon.anticon-delete {
        color: #dc3545;
    }
}
.student-dashboard {
    .navbar {
        font-weight: 300;
        box-shadow: 0 0 0 0 #2b5060, 0 0 0 0 rgba(0, 0, 0, 0.12); 
    }
}
.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
}
  
.steps-action {
    margin-top: 24px;
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon
{
    margin-right: 10px !important;
}
.esh-mans {
    .ant-radio-group {
        span {
            display: inline-block;
            vertical-align: middle;
            textarea.ant-input {
                display: block;
                width: 100%;
            }
        }
    }
}
.esh-mans {
    .ant-radio-group{
        span{
            &:nth-child(1) {
                width: 16px;
            }
            &:nth-child(2) {
                width: 100%;
            }
        }
    }
}
span.add-remove-icon {
    float: left;
    position: absolute;
    right: -30px;
    top: 50px;
    span {
        display: block;
    }
}
.esh-add-que {
    .ant-tabs-bar {
        margin: 0;
        padding: 0px 10px;
    }
}
.ant-select-selection-placeholder {
    text-align: left;
}
.ant-select-selection-item {
    text-align: left;
}
.ant-tp-type {
    .ant-card-body {
        padding: 10px;
    }
}
.ant-tp-type {
    .ant-card-body {
        .ant-form-item-label {
            text-align: center;
        }
    }
}
.ant-tp-type {
    .ant-card-body {
        .ant-form-item {
            margin-bottom: 10px;
        }
    }
}
.ant-tp-type{ 
    .ant-card-body {
        .ant-col.ant-form-item-control {
            text-align: center;
        }
    }
}
.answer-option {
    .ant-radio-group.ant-radio-group-outline {
        width: 100%;
    }
}
.cu-policy-holder {
    .ant-form-vertical {
        display: inline-block;  
    }
    label.text-left {
        display: block;
        margin-top: 5px;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
    }
}
.cu-accordin {
	>.ant-collapse-item {
		>.ant-collapse-header {
			color: #fff !important;
			background: #443e97 !important;
			border-radius: 2px;
		}
	}
}
.ant-btn-primary {
    color: #fff;
    background-color: #001138 !important;
    border-color: #001138 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    background-color: #443e97 ;
    border-color: #443e97 ;
}
.ant-form-item-label > label {
    height: auto !important;
    margin-bottom: 5px !important;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #443e97 !important;
}
.cu-breadcumb span.anticon {
    color: #58c5c4 !important;
}
.admin-layout {
    .logo img {
        max-width: 50px;
        height: auto;
    }
}
ul.form-list {
    li {
        text-align: left;
    }
}

.capitalise {
    text-transform:capitalize;
}

.ant-btn.ant-btn-theme {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #443e97;
    border: #443e97;
    color: #fff;
}

.ant-btn.ant-btn-theme:hover {
    background: #322d74;
    border: #322d74;
    color: #fff;
}

.ant-btn.ant-btn-theme:focus {
    background: #322d74;
    border: #322d74;
    color: #fff;
}
.file-upload {
    .ant-upload-picture-card-wrapper {
        .ant-upload.ant-upload-select-picture-card {
            display: table !important;
            float: unset !important;
            margin: 0 auto !important;
            // .ant-upload {
            //     display: block !important;
            // }
        }
    }
}
.ant-layout-header {
    overflow: hidden;
}
.ant-modal-title {
    font-weight: 600 !important;
}
footer.ant-layout-footer {
    padding: 15px 50px !important;
}
.cu-sb.ant-layout-sider {
    background: #001A59 !important;
}
.cu-sb.ant-layout-sider{
    div{
         ul.ant-menu.ant-menu-dark {
            background: #001A59 !important;
            transition: .3s all;
            li.ant-menu-item.ant-menu-item-selected {
                background: #001138 !important;
            }
        }
    }
}
.ant-btn-link-danger.ant-btn-circle .anticon-close-circle {
    color: #dc3545 !important;
}
.ant-btn-link .anticon-edit {
    color: #001138 !important;
}
.ant-btn-link {
    color: #002fa7 !important;
}
.ant-btn.ant-btn-link.ant-btn-circle .anticon-unlock {
    color: #002FA7 !important;
}
.ant-switch-checked {
    background-color: #002FA7 !important;
}
.ant-pagination-item-active {
    border-color: #002FA7 !important;
}
.appointment_status {
    .ant-collapse-header {
        text-align: left;
        padding: 8px 16px 8px 40px !important;
    }
    .ant-collapse-content {
        .ant-collapse-content-box{
            padding-bottom: 8px !important;
            .ant-timeline-item-head-blue {
                color: #002FA7 !important;
                border-color: #002FA7 !important;
            }
        }
        .ant-timeline-item {
            padding-bottom: 8px !important;
        }
        .ant-timeline-item.ant-timeline-item-last
        {
            padding-bottom: 0px !important;
            .ant-timeline-item-content{
                min-height: 0 !important;
                text-align: left;
            }
        }
        .appointment_time {
            width: 70px;
            display: inline-block;
        }
    }
}
.ant-checkbox-checked {
	.ant-checkbox-inner {
		background-color: #002FA7 !important;
		border-color: #002FA7 !important;
	}
}
.ant-checkbox-wrapper {
	&:hover {
		.ant-checkbox-inner {
			border-color: #002FA7 !important;
		}
	}
}
.ant-checkbox {
	&:hover {
		.ant-checkbox-inner {
			border-color: #002FA7 !important;
		}
	}
}
.ant-checkbox-input {
	&:focus {
		+ {
			.ant-checkbox-inner {
				border-color: #002FA7 !important;
			}
		}
	}
}
.ant-table {
	thead {
		>tr {
			>th {
				font-weight: 600 !important;
				font-size: 15px !important;
			}
		}
	}
}

// .space-nowrap{
//     white-space: nowrap !important;
// }
// .ant-picker-time-panel-column:not(:first-child) {
//     padding: 0 0 76px 0;
// }
.ant-table thead > tr > th, .ant-table tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 4px !important;
    overflow-wrap: break-word;
}
.ant-layout-header {
    height: 45px !important;
    line-height: 45px !important;
}
.ant-table-pagination.ant-pagination {
    margin: 8px 0 0px 0 !important;
}
.ant-layout-content {
    padding: 10px !important;
    margin: 15px 15px 0px !important;
}
.ant-table-content {
    overflow: auto hidden;
}
.cu-pb-3 {
    padding-bottom: 10px;
}
span.fc-day-number {
    float: none !important;
    font-size: 16px;
    margin-top: 8px;
    font-family: Roboto,Arial,sans-serif;
    font-weight: 500;
    letter-spacing: .3px;
    text-align: center;
    color: #70757a;
    line-height: 16px;
}
td.fc-day-top {
    text-align: center;
    padding-top: 10px;
}
.fc-scroller.fc-day-grid-container {
    overflow: hidden;
    max-height: 726.6px;
    height: auto !important;
} 
.fc-dayGrid-view {
    .fc-body {
        .fc-row {
            min-height: 4.5em;
            height: auto !important;
        }
    }
}
.fc-button-primary:disabled {
    color: #fff;
    background-color: #001138;
    border-color: #001138;
}
.fc-button-primary {
    color: #fff;
    background-color:#001138;
    border-color: #001138;
}
.fc-button-primary:hover {
    color: #fff;
    background-color:#001138;
    border-color: #001138 ;
}
.fc-button-primary:active {
    color: #fff;
    background-color:#001138 !important;
    border-color: #001138 !important;
}
.fc-button-primary:focus {
    box-shadow: 0 0 0 0 rgba(0, 17, 56, .19) !important;
}
.fc-toolbar{
    padding: 0 !important;
}
.fc-unthemed td.fc-today {
    background: rgba(0, 17, 56, .19);
}
.ant-table-content {
    overflow: auto !important;
}
.fc-event{
    background-color: #002FA7 !important;
    border: 1px solid #dddddd !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected {
	.ant-picker-cell-inner {
		color: #fff;
		background: #002FA7;
	}
}
.ant-picker-cell-in-view.ant-picker-cell-range-start {
	.ant-picker-cell-inner {
		color: #fff;
		background: #002FA7;
	}
}
.ant-picker-cell-in-view.ant-picker-cell-range-end {
	.ant-picker-cell-inner {
		color: #fff;
		background: #002FA7;
	}
}


.appointment-tab{
    .ant-tabs-nav-scroll {
        text-align: center;
        .ant-tabs-tab-active.ant-tabs-tab {
            border: 1px solid #002FA7 !important;
            color: #002FA7 !important;
            height: auto !important;
            line-height: normal !important;
        }
        .ant-tabs-tab{
            height: auto !important;
            line-height: normal !important;
        }
        .ant-tabs-nav {
            .ant-tabs-tab:hover {
                color: #002FA7;
            }
        }
    }
    .ant-tabs-bar {
        border-bottom: 0px solid #f0f0f0 !important;
    }   
}
.tableview {
    .appointment-tab {
        .ant-tabs-bar.ant-tabs-top-bar {
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate(-50%);
            top: 0px;
            padding-bottom: 10px;
        }
    }
}
.fc-today-button {
    margin-left: 5px;
}
.calendarview {
    .appointment-tab {
        .ant-tabs-bar.ant-tabs-top-bar {
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate(-50%);
            top: 0px;
        }
    }
    .fc td, .fc th {
        border-style: solid;
        border-width: 1px;
        padding: 0;
        vertical-align: top;
        border-color: #ddd;
    }
    .fc-timeGrid-view .fc-day-grid {
        position: relative;
        z-index: 2;
    }
    .fc-timeGrid-view .fc-day-grid .fc-row {
        min-height: 3em;
    }
    .fc-timeGrid-view .fc-day-grid .fc-row .fc-content-skeleton {
        padding-bottom: 1em;
    }
    .fc-ltr .fc-axis {
        text-align: right;
    }
    .fc-time-grid>.fc-bg {
        z-index: 1;
    }
    .fc-time-grid table {
        border: 0 hidden transparent;
    }
    .fc table {
        width: 100%;
        box-sizing: border-box;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 1em;
    }    
    .fc-bg table {
        height: 100%;
    }
    .fc-scroller.fc-time-grid-container {
        max-height: 420px;
    }
    .fc-ltr .fc-axis {
        text-align: right;
    }
    .fc .fc-axis {
        vertical-align: middle;
        padding: 0 4px;
        white-space: nowrap;
        width: 70px !important;
    }
    .fc-time-grid .fc-slats, .fc-time-grid>hr {
        position: relative;
        z-index: 2;
    }
    .fc-time-grid .fc-slats td {
        height: 1.5em;
        border-bottom: 0;
        span{
            font-size: 14px;
            color: #000000;
            padding: 0 5px;
        }
    }
}
.fc-toolbar h2 {
    font-size: 20px;
    margin: 0;
    float: right;
    margin-left: 20px;
    margin-top: 6px;
}
.fc-prev-button {
    border-radius: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.fc-next-button {
    border-radius: 0.25em;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.fc-button.fc-button-primary {
    text-transform: capitalize;
}
.fc-time-grid .fc-bgevent, .fc-time-grid .fc-event {
    position: absolute;
    z-index: 1;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(0, 150, 242, .1) !important;
}
@media (max-width:767px){
    .tableview .appointment-tab .ant-tabs-bar.ant-tabs-top-bar {
        position: relative;
        z-index: 1;
        left: unset;
        transform: unset;
        top: unset;
        padding-bottom: unset;
    }
    .calendarview .appointment-tab .ant-tabs-bar.ant-tabs-top-bar {
        position: relative;
        z-index: 1;
        left: unset;
        transform: unset;
        top: unset;
    }
}
.register-form {
    .wrap-login100 {
        padding: 20px 20px 10px 20px;
        .login100-form {
            width: 600px;
        }
    }
    .login100-form-title {
        padding-bottom: 20px;
    }
    .checkbox.icheck {
        label {
            margin-bottom: 0;
            .ant-row.ant-form-item {
                margin-bottom: 0 !important;
            }
        }
    }
    .ant-form-item {
        margin-bottom: 20px;
    }
    .container-login100 {
        padding: 0px; 
    }
}
.select-appointment {
    .login100-form {
        width: 100%;
    }
    .wrap-login100 {
        padding: 20px 20px 20px 20px;
    }
    .doctor-img {
        text-align: center;
    }
    .doctor-img img {
        max-width: 50%;
    }
    .selected-details{
        p{
            color: #000;
            margin-bottom: 5px;
        }
        table {
            width: 100%;
            td{
                vertical-align: top;
            }
        }
    }
    .appointment-tab .ant-tabs-bar {
        display: none;
    }
}
.fw-100{
    font-weight: 600;
}
.payment{
    .wrap-login100{
        padding: 30px;
    }
    .container-login100-form-btn {
        padding: 20px 50px 0 50px;
    }
    p{
        color: #000000;
        font-size: 16px;
        margin-bottom: 5px;
    }
    table {
        width: 100%;
        td{
            vertical-align: top;
        }
    }
    .bg-white {
        border-radius: 10px;
        padding: 15px;
    }
    .cu-tion{
        border-bottom: 1px solid rgba(151, 151, 151, 0.53);
        .data{
            span{
                line-height: normal;
            }
            .heading {
                font-size: 18px;
                font-weight: bold;
                color: #000;
            }
            .p{
                color: #000000;
                font-size: 14px;
                font-weight: 300;
            }
        }
        .icon-img {
            width: 45px;
        }        
    }
}
.appointment-book{
    .login100-form {
        width: 700px;
    }
    .d-profile-img {
        img {
            padding: .25rem;
            background-color: #fff;
            border: 1px solid #dee2e6;
            border-radius: 50%;
            max-width: 150px;
            height: 150px;
            object-fit: cover;
        }
    }
    .dr-details {
        padding: 0 16px;
    }
    .wrap-login100{
        padding: 20px 20px 20px 20px;
    }
}
.t-color-1{
    color:#58c5c4;
}
.t-color-2{
    color: #443e97;
}
.small-text {
    font-size: 12px;
}
.select-appoint{
    .ant-tabs-nav-scroll {
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background: transparent; 
        }
    }
    .cu-dt {
        .appoint-logo {
            width: 20px;
            margin-right: 10px;
        }
        .left-side {
            width: 110px;
        }
    }   
    .ant-tabs-nav{
        width: 100%;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent; 
            scrollbar-width: none;
        }
        .ant-tabs-tab {
            width: 23%;
            text-align: center;
            margin: 0 10px 0 0;
            &:hover{
                color: #443e97;
            }
        }
        
        .ant-tabs-tab-active {
            color: #443e97;
        }
        .ant-tabs-ink-bar {
            background-color: #443e97;
        }
    }
}
.checkbox.icheck.DPM {
    .ant-form-item {
        margin-bottom: 0;
    }
}
.payment-btns {
    padding: 10px 50px 0px 50px;
}
.back-btns {
    position: absolute;
    top: 15px;
    left: 15px;
}
.payable .ant-form-item-label > label::after {
    content: '';
    position: relative;
    top: -0.5px;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background: rgba(0, 150, 242, .1) !important;
}
.float-center {
    display: flex;
    justify-content: center;
}
.search-bar-long {
    width: 100% !important;
}
.fc-time-grid .fc-mirror-container {
    position: relative;
    z-index: 6;
}
.fc-ltr .fc-time-grid .fc-event-container {
    margin: 0 2.5% 0 2px;
}
.fc-time-grid .fc-event-container {
    position: relative;
    z-index: 4;
}
.fc-time-grid-event {
    margin-bottom: 1px;
}
.fc-time-grid .fc-content-col {
    position: relative;
}
.fc-time-grid .fc-content-skeleton {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
}

// .fc-time-grid-event.fc-short .fc-time span {
//     display: inline;
// }

// .fc-time-grid-event.fc-short .fc-time:before {
//     content: normal;
// }  

// .fc-time-grid-event.fc-short .fc-time:after {
//     content: normal;
// }
// .fc-time-grid .fc-content-skeleton {
//     position: absolute;
//     z-index: 3;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 100%; }
@media (max-width:767px){
    .register-form .container-login100 {
        padding: 0 10px;
    }
    .step1 .wrap-login100 {
        padding: 30px 15px 20px 15px;
    }
    .payment .wrap-login100 {
        padding: 30px 15px 20px 15px;
    }
    .appointment-book {
        .login100-form {
            max-width: 700px;
            width: 100%;
        }
        .d-profile-img {
            text-align: center !important;
        }
        .ant-card-body {
            .d-flex.justify-content-between {
                display: block !important;
                justify-content: unset !important;
                .d-flex{
                    display: block !important;
                }
            }
            .dr-details {
                text-align: center;
            }
            .appoint-sec {
                text-align: center;
            }
            .select-appoint {
                .ant-tabs-nav {
                    .ant-tabs-tab {
                        width: 100%;
                        text-align: center;
                        margin: 0 10px 0 0;
                    }
                }
                .ant-btn-round.ant-btn-lg {
                    height: auto;
                    white-space: unset;
                }
            }
        }
    }
    .payable {
        .ant-form-item-label {
            text-align: center !important;
        }
    }
}


.tableview {
	.appointment-tab {
		position: relative;
		.ant-tabs-nav {
			position: absolute;
			z-index: 1;
			left: 50%;
			-webkit-transform: translate(-50%);
			transform: translate(-50%);
			top: 0;
			padding-bottom: 10px;
			.ant-tabs-nav-list {
				text-align: center;
				.ant-tabs-tab {
					margin: 0 2px 0 0;
					padding: 8px 16px;
					line-height: 22px;
					background: #fafafa;
					border: 1px solid #f0f0f0;
					border-radius: 2px 2px 0 0;
					-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					height: auto !important;
					line-height: normal !important;
				}
				.ant-tabs-tab.ant-tabs-tab-active {
					border: 1px solid #002fa7 !important;
					color: #002fa7 !important;
					height: auto !important;
					line-height: normal !important;
				}
			}
		}
	}
}
.calendarview {
	.appointment-tab {
		position: relative;
		.ant-tabs-nav {
			position: absolute;
			z-index: 1;
			left: 50%;
			-webkit-transform: translate(-50%);
			transform: translate(-50%);
			top: 0;
			padding-bottom: 10px;
			.ant-tabs-nav-list {
				text-align: center;
				.ant-tabs-tab {
					margin: 0 2px 0 0;
					padding: 8px 16px;
					line-height: 22px;
					background: #fafafa;
					border: 1px solid #f0f0f0;
					border-radius: 2px 2px 0 0;
					-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					height: auto !important;
					line-height: normal !important;
				}
				.ant-tabs-tab.ant-tabs-tab-active {
					border: 1px solid #002fa7 !important;
					color: #002fa7 !important;
					height: auto !important;
					line-height: normal !important;
				}
			}
		}
	}
}

.requred-feild {
    .ant-form-item-label {
        label:before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: '*';
        }
    }
    .cu-labels {
        span:before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: '*';
        }
    }
}
